<template>
  <div
    id="stepperForm"
    class="mt-0"
  >
    <v-stepper
      v-model="activeStep"
      class="custom-header rounded-xl"
    >
      <!-- Headers -->
      <v-stepper-header>
        <!-- Header: Step 1 -->
        <v-stepper-step
          :complete="activeStep > 1"
          step="1"
          :rules="[() => stepValidationResult[1]]"
        >
          <div class="d-flex align-center">
            <span
              class="text-4xl font-weight-bold"
              :class="stepValidationResult[1] ? 'text--primary' : 'error--text'"
            ></span>
          </div>
        </v-stepper-step>

        <v-divider></v-divider>

        <!-- Header: Step 2 -->
        <v-stepper-step
          :complete="activeStep > 2"
          step="2"
          :rules="[() => stepValidationResult[2]]"
        >
          <div class="d-flex align-center">
            <span
              class="text-4xl font-weight-bold"
              :class="stepValidationResult[2] ? 'text--primary' : 'error--text'"
            ></span>
          </div>
        </v-stepper-step>

        <v-divider></v-divider>

        <!-- Header: Step 3 -->
        <v-stepper-step
          step="3"
          :rules="[() => stepValidationResult[3]]"
        >
          <div class="d-flex align-center">
            <span
              class="text-4xl font-weight-bold"
              :class="stepValidationResult[3] ? 'text--primary' : 'error--text'"
            ></span>
          </div>
        </v-stepper-step>
      </v-stepper-header>

      <!-- Stepper Items -->
      <div>
        <v-stepper-items>
          <!-- Stepper Content: Step 1 -->
          <v-stepper-content step="1">
            <EventFirstStep
              :event-data="stepsFormData"
              :move-to-next-step="(data) => handleStepValidation(1, data)"
              :not-available-lang="notAvailableLang"
            />
          </v-stepper-content>

          <!-- Stepper Content: Step 2 -->
          <v-stepper-content
            step="2"
          >
            <EventSecondStep
              :event-data="stepsFormData"
              :new-event="newEvent"
              :move-to-next-step="(data) => handleStepValidation(2, data)"
              :move-to-previous-step="()=> handleStepValidation(2, null, false)"
            ></EventSecondStep>
          </v-stepper-content>

          <!-- Stepper Content: Step 3 -->
          <v-stepper-content step="3">
            <EventLastStep
              :event-data="stepsFormData"
              :move-to-next-step="(data) => handleStepValidation(3, data, true, true)"
              :move-to-previous-step="()=> handleStepValidation(3, null, false)"
              :new-event="newEvent"
            ></EventLastStep>
          </v-stepper-content>
        </v-stepper-items>
      </div>
    </v-stepper>
  </div>
</template>

<script>
import { i18n } from '@/plugins/i18n/index'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import EventFirstStep from './EventFirstStep.vue'
import EventLastStep from './EventLastStep.vue'
import EventSecondStep from './EventSecondStep.vue'

export default {
  components: {
    EventFirstStep,
    EventSecondStep,
    EventLastStep,
  },
  props: {
    eventData: {
      type: Object,
      default: null,
    },
    newEvent: {
      type: Boolean,
    },
    notAvailableLang: {
      type: Array,
      default: null,
    },
  },

  setup(props, { emit }) {
    const toast = useToast()
    const showToast = () => toast.error(i18n.t('errorNewEvent'))
    const clearToasts = () => toast.clear()

    // Stepper's active step
    const activeStep = ref(1)
    const stepsFormData = ref({
      languages: props.eventData.languages,
      names: props.eventData.names,
      defaultLang: props.eventData.defaultLang,
      specialMeal: props.eventData.specialMeal,
      specialMeals: props.eventData.specialMeals,
      transport: props.eventData.transport,
      transports: props.eventData.transports,
      date: props.eventData.date,
      time: props.eventData.date,
      location: props.eventData.location,
      image: props.eventData.image,
    })

    // It will be updated when step is validated
    const stepValidationResult = ref({
      1: true,
      2: true,
      3: true,
    })

    // Step Validation
    const handleStepValidation = (step, data = null, navigateNext = true, isFinalStep = false) => {
      stepsFormData.value = {
        ...stepsFormData.value,
        ...data,
      }

      const isStepValid = true
      stepValidationResult.value[step] = isStepValid

      if (isStepValid) {
        if (navigateNext && !isFinalStep) activeStep.value += 1
        else if (!navigateNext) activeStep.value -= 1

        if (isFinalStep) {
          const formData = new FormData()

          const setFormData = object => {
            stepsFormData.value.languages = stepsFormData.value.languages.map(el => el.lang)
            Object.entries(object).reduce((fd, [key, val]) => {
              if (key === 'location') {
                Object.keys(val).forEach(nestedKey => {
                  fd.append(`${key}[${nestedKey}]`, val[nestedKey])
                })
              } else if (key === 'names') {
                val.forEach((v, index) => {
                  fd.append(`${key}[${index}][name]`, val[index].name)
                  fd.append(`${key}[${index}][lang]`, val[index].lang)
                })
              } else if (Array.isArray(val)) {
                val.forEach((v, index) => {
                  const objectField = key === 'transports' ? 'name' : 'value'
                  let keyString = `${key}[${index}]`

                  if (key !== 'languages') {
                    keyString += `[${objectField}]`
                  }
                  fd.append(keyString, v)
                })
              } else {
                fd.append(key, val)
              }

              return fd
            }, formData)
          }

          setFormData(stepsFormData.value)

          emit('submit', formData, () => {
            activeStep.value = 1
          })
        }
      }
    }

    return {
      stepsFormData,
      activeStep,
      showToast,
      clearToasts,

      // Step Validations
      stepValidationResult,
      handleStepValidation,
    }
  },
}
</script>

<style scoped>
#stepperForm {
  max-width: 600px;
  margin: 50px auto;
}
.v-progress-circular {
  display: flex;
  position: relative;
  margin-top: 150px;
}

.v-stepper__content {
  padding: 10px 10px 20px 10px !important;
}
</style>
