<template>
  <div>
    <v-card
      color="transparent"
      class="form-card mb-6 overflow-y-auto"
      min-height="400px"
      max-height="1000px"
      flat
    >
      <v-card-text>
        <v-form
          ref="step2Form"
        >
          <v-row
            align="center"
            justify="center"
          >
            <!-- event date -->
            <v-col
              cols="12"
              md="8"
            >
              <v-dialog
                ref="dialog"
                v-model="dialogModel"
                :return-value.sync="stepData.date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    rounded
                    :value="formattedDate"
                    :rules="[validators.required]"
                    :label="$t('EventDate')"
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    hide-details
                    class="justify-center align-center"
                    v-on="on"
                  >
                    <template #prepend>
                      <v-avatar
                        size="36"
                        class="v-avatar-light-bg primary--text"
                      >
                        <v-icon
                          size="26"
                          color="primary"
                        >
                          {{ icons.mdiCalendar }}
                        </v-icon>
                      </v-avatar>
                    </template>
                  </v-text-field>
                </template>

                <v-date-picker
                  v-model="stepData.date"
                  scrollable
                  color="primary"
                  no-title
                  :min="minDate"
                  class="rounded-xl"
                >
                  <v-btn
                    text
                    color="primary"
                    @click="dialogModel = false"
                  >
                    {{ $t('Cancel') }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(stepData.date)"
                  >
                    {{ $t('Ok') }}
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <!-- event time -->
            <v-col
              cols="12"
              md="8"
            >
              <v-dialog
                ref="dialogTime"
                v-model="timeDialog"
                :return-value.sync="stepData.time"
                persistent
                width="300px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="stepData.time"
                    :label="$t('EventTime')"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    outlined
                    hide-details
                    dense
                    class="justify-center align-center"
                    :rules="[validators.required]"
                    rounded
                    v-on="on"
                  >
                    <template #prepend>
                      <v-avatar
                        size="36"
                        class="v-avatar-light-bg primary--text"
                      >
                        <v-icon
                          size="26"
                          color="primary"
                        >
                          {{ icons.mdiClockOutline }}
                        </v-icon>
                      </v-avatar>
                    </template>
                  </v-text-field>
                </template>
                <v-time-picker
                  v-if="timeDialog"
                  v-model="stepData.time"
                  full-width
                  format="24hr"
                  scrollable
                  class="rounded-xl"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="timeDialog = false"
                  >
                    {{ $t('Cancel') }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialogTime.save(stepData.time)"
                  >
                    {{ $t('Ok') }}
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>

            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                v-model="stepData.eventLocationName"
                :label="$t('EventLocationName')"
                :rules="[validators.maxLenInput, validators.required, validators.minLenInput]"
                maxlength="30"
                required
                dense
                hide-details
                class="justify-center align-center"
                outlined
                rounded
                multiple
                @change="changeEventLocationName"
              >
                <template #prepend>
                  <v-avatar
                    size="36"
                    class="v-avatar-light-bg primary--text"
                  >
                    <v-icon
                      size="26"
                      color="primary"
                    >
                      {{ icons.mdiMapMarker }}
                    </v-icon>
                  </v-avatar>
                </template>
              </v-text-field>
            </v-col>

            <!-- event location -->
            <v-col
              cols="12"
              md="8"
            >
              <vuetify-google-autocomplete
                id="map"
                v-model="stepData.location"
                :rules="[validators.required, validators.minLenInput]"
                types=""
                country="IL"
                clearable
                :placeholder="$t('EventLocationGoogle')"
                :place-name="true"
                outlined
                dense
                rounded
                hide-details
                class="justify-center align-center align-self-center"
                @placechanged="setAddress"
              >
                <template #prepend>
                  <v-avatar
                    size="36"
                    class="v-avatar-light-bg primary--text"
                  >
                    <v-icon
                      size="26"
                      color="primary"
                    >
                      {{ icons.mdiMapMarker }}
                    </v-icon>
                  </v-avatar>
                </template>
              </vuetify-google-autocomplete>
            </v-col>
            <!-- special meals types -->
            <v-col
              cols="12"
              md="8"
            >
              <v-select
                v-model="stepData.specialMeal"
                :items="specialMealsTypes"
                :item-text="item => $t(item.name)"
                item-value="value"
                :label="$t('specialMeals')"
                hide-details
                menu-props="closeOnContentClick"
                class="justify-center align-center"
                outlined
                dense
                rounded
              >
                <template #prepend>
                  <v-avatar
                    size="36"
                    class="v-avatar-light-bg primary--text"
                  >
                    <v-icon
                      size="26"
                      color="primary"
                    >
                      {{ icons.mdiSilverwareForkKnife }}
                    </v-icon>
                  </v-avatar>
                </template>
              </v-select>
            </v-col>

            <!-- Special meals options select -->
            <v-col
              v-if="stepData.specialMeal !== mealsTypes.NO_NEED_TO_ASK"
              cols="12"
              md="8"
            >
              <v-select
                v-model="stepData.specialMeals"
                :items="mealOption"
                :item-text="item => $t(item.name)"
                item-value="value"
                small-chips
                :rules="[validators.required]"
                :label="$t('ChooseSpecialMeals')"
                multiple
                hide-details
                menu-props="closeOnContentClick"
                class="justify-center align-center"
                outlined
                dense
                rounded
              >
                <template #prepend>
                  <v-avatar
                    size="36"
                    class="v-avatar-light-bg primary--text"
                  >
                    <v-icon
                      size="26"
                      color="primary"
                    >
                      {{ icons.mdiSilverwareForkKnife }}
                    </v-icon>
                  </v-avatar>
                </template>
                <template #selection="{ item }">
                  <v-chip
                    small
                    class="mt-2 px-1"
                  >
                    <span>{{ $t(item.name) }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>

            <!-- Transportation options -->
            <v-col
              cols="12"
              md="8"
            >
              <v-select
                v-model="stepData.transport"
                :items="transportOptions"
                :label="$t('Transport')"
                :item-text="item => $t(item.name)"
                item-value="value"
                hide-details
                class="justify-center align-center"
                dense
                rounded
                outlined
              >
                <template #prepend>
                  <v-avatar
                    size="36"
                    class="v-avatar-light-bg primary--text"
                  >
                    <v-icon
                      size="26"
                      color="primary"
                    >
                      {{ icons.mdiBus }}
                    </v-icon>
                  </v-avatar>
                </template>
              </v-select>
            </v-col>

            <!-- Transportation stops -->
            <v-col
              cols="12"
              md="8"
              sm="10"
            >
              <v-combobox
                v-if="stepData.transport !== transportTypes.NO_NEED_TO_ASK"
                v-model="openTransChips"
                :filter="filter"
                :hide-no-data="!search"
                :search-input.sync="search"
                hide-selected
                :rules="[validators.required]"
                :label="$t('GetPlacesName')"
                multiple
                chips
                class="justify-center align-center"
                closable-chips
                hide-details
                rounded
                dense
                outlined
              >
                <template #prepend>
                  <v-avatar
                    size="36"
                    class="v-avatar-light-bg primary--text"
                  >
                    <v-icon
                      size="26"
                      color="primary"
                    >
                      {{ icons.mdiBusStop }}
                    </v-icon>
                  </v-avatar>
                </template>
                <template #no-data>
                  <v-list-item>
                    <span class="subheading">{{ $t('AddName') }}</span>
                    <v-chip
                      small
                    >
                      {{ search }}
                    </v-chip>
                  </v-list-item>
                </template>
                <template #selection="{ attrs, item, parent, selected }">
                  <v-chip
                    v-if="item === Object(item)"
                    v-bind="attrs"
                    :input-value="selected"
                    small
                    class="mt-2 px-1"
                  >
                    <span class="pe-1">
                      {{ item.name }}
                    </span>
                    <v-icon
                      small
                      icon
                      @click="parent.selectItem(item)"
                    >
                      {{ icons.mdiClose }}
                    </v-icon>
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <div class="d-flex justify-space-between">
      <v-btn
        rounded
        outlined
        @click="moveToPrevious()"
      >
        {{ $t('Previous') }}
      </v-btn>
      <v-btn
        rounded
        color="primary"
        @click="validateForm()"
      >
        {{ $t('Next') }}
      </v-btn>
    </div>
    <TimingNote
      v-if="dateTimeChanged"
      @submit="agreeNote"
      @close="dateTimeChanged = false"
    ></TimingNote>
  </div>
</template>

<script>
import useTransport from '@/composables/useTransport'
import useSpecialMealsTypes from '@/composables/useSpecialMealsTypes'
import store from '@/store'
import { maxLenInput, minLenInput, required } from '@core/utils/validation'
// eslint-disable-next-line object-curly-newline
import { i18n } from '@/plugins/i18n/index'
import {
  mdiBus,
  mdiBusStop,
  mdiCalendar,
  mdiClockOutline,
  mdiClose,
  mdiMapMarker,
  mdiSilverwareForkKnife,
  mdiWeb,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import moment from 'moment'
import { useToast } from 'vue-toastification/composition'
import useWorkingDays from '@/composables/useWorkingDays'
import TimingNote from '../TimingNote.vue'

export default {
  components: { TimingNote },
  props: {
    eventData: {
      default: null,
      type: Object,
    },

    moveToNextStep: {
      type: Function,
      default: null,
    },
    moveToPreviousStep: {
      type: Function,
      default: null,
    },
    newEvent: {
      type: Boolean,
    },
  },
  setup(props) {
    const { transportOptions, transportTypes } = useTransport()
    const { specialMealsTypes, mealsTypes } = useSpecialMealsTypes()
    const toast = useToast()

    const getDatetime = date => {
      const newDate = new Date(date)
      const hours = newDate.getHours() < 10 ? `0${newDate.getHours()}` : newDate.getHours()
      const minutes = newDate.getMinutes() < 10 ? `0${newDate.getMinutes()}` : newDate.getMinutes()

      return `${hours}:${minutes}`
    }

    const now = new Date()

    // The minimum date for the event is 5 business days
    const minWorkingDate = useWorkingDays(now, 5)
    const minDate = ref(minWorkingDate)

    // Once payment has been made, the user cannot set the event date to a day prior to the date selected at the time of payment
    const minDateAfterPayment = () => {
      if (props?.eventData?.progressStatus > 0) {
        minDate.value = props?.eventData?.date?.substring(0, 10)
      }
    }
    minDateAfterPayment()

    const stepData = ref({
      specialMeal: props?.eventData?.specialMeal ?? mealsTypes.NO_NEED_TO_ASK,
      specialMeals: props?.eventData.specialMeals ? props.eventData.specialMeals.map(el => el.value) : [],
      transport: props?.eventData?.transport ?? transportTypes.NO_NEED_TO_ASK,
      transports: props?.eventData?.transports ?? [],
      date: props?.eventData?.date ? props?.eventData?.date?.substring(0, 10) : '',
      time: props?.eventData?.date ? getDatetime(props.eventData.date) : '',
      location: props?.eventData?.location?.name ?? '',
      address: props?.eventData?.location ?? {},
      eventLocationName: props?.eventData?.location?.eventLocationName ?? '',
    })

    const formattedDate = computed(() => {
      if (!stepData.value.date) return ''

      return moment(stepData.value.date).format('DD/MM/YYYY')
    })

    const dialogModel = ref(false)
    const timeDialog = ref(false)

    watch(
      () => stepData.value.location,
      newValue => {
        if (!newValue) {
          stepData.value.address = ''
        }
      },
    )

    const changeEventLocationName = name => {
      stepData.value.address.eventLocationName = name
    }

    const setAddress = addressData => {
      stepData.value.address = {
        name: addressData.name ? addressData.name : '',
        longitude: addressData.longitude ? addressData.longitude.toString() : '',
        latitude: addressData.latitude ? addressData.latitude.toString() : '',
        eventLocationName: stepData.value.eventLocationName ?? '',
      }
    }

    const step2Form = ref(null)
    const mealOption = ref()
    const openTransChips = ref()
    openTransChips.value = stepData.value.transports

    const search = ref()

    const getMeal = () => {
      store.dispatch('getMeals').then(res => {
        mealOption.value = res.data
      })
    }
    getMeal()
    const dateTimeChanged = ref(false)
    const canMoveToNextStep = ref(false)

    const validateForm = () => {
      // Edit event
      if (!props.newEvent) {
        // old date and time
        const savedDateAndTime = new Date(store.state.eventData.date)
        const date = moment(savedDateAndTime).format('YYYY-MM-DD')
        const time = moment(savedDateAndTime).format('HH:mm')

        // If user changed date or time, show warning
        if (date !== stepData.value.date || time !== stepData.value.time) {
          if (!canMoveToNextStep.value) {
            canMoveToNextStep.value = false
            dateTimeChanged.value = true
          }
        } else {
          dateTimeChanged.value = false
          canMoveToNextStep.value = true
        }
      } else {
        canMoveToNextStep.value = true
      }

      // eslint-disable-next-line object-curly-newline
      const { specialMeal, specialMeals, transport, date, time, address } = stepData.value
      if (step2Form.value.validate() && canMoveToNextStep.value) {
        if (address.name) {
          props.moveToNextStep({
            specialMeal,
            specialMeals: specialMeal !== mealsTypes.NO_NEED_TO_ASK ? specialMeals : [],
            transport,
            transports: transport !== transportTypes.NO_NEED_TO_ASK ? openTransChips.value.map(el => el.name) : [],
            date: `${date} ${time}`,
            location: address,
          })
          canMoveToNextStep.value = false
          dateTimeChanged.value = false
        } else {
          toast.error(i18n.t('must_choose_from_google_location'))
          stepData.value.location = ''
          stepData.value.address = ''
        }
      }
    }

    const agreeNote = () => {
      canMoveToNextStep.value = true
      validateForm()
    }

    const moveToPrevious = () => {
      props.moveToPreviousStep()
    }

    const filter = (item, queryText, itemText) => {
      if (item.header) return false

      const hasValue = val => (val != null ? val : '')

      const name = hasValue(itemText)
      const query = hasValue(queryText)

      return name.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1
    }

    watch(openTransChips, (val, prev) => {
      if (val.length === prev.length) return

      openTransChips.value = val.map(v => {
        if (typeof v === 'string') {
          // eslint-disable-next-line no-param-reassign
          v = {
            name: v,
          }
        }

        return v
      })
    })

    return {
      step2Form,
      validateForm,
      stepData,
      mealOption,
      getMeal,

      // checkIfChange,
      transportOptions,
      transportTypes,
      openTransChips,
      filter,
      search,
      moveToPrevious,
      getDatetime,
      minDate,
      formattedDate,
      dialogModel,
      timeDialog,
      setAddress,
      changeEventLocationName,
      specialMealsTypes,
      mealsTypes,
      dateTimeChanged,
      canMoveToNextStep,
      agreeNote,

      validators: {
        required,
        maxLenInput,
        minLenInput,
      },
      icons: {
        mdiWeb,
        mdiSilverwareForkKnife,
        mdiBus,
        mdiBusStop,
        mdiClose,
        mdiCalendar,
        mdiMapMarker,
        mdiClockOutline,
      },
    }
  },
}
</script>
